import React, { useState, useEffect, useRef } from 'react';

const ScrollColorChange = ({ children, className = '', minColor = 'rgba(57, 57, 57, 1)', maxColor = 'rgba(255, 255, 255, 1)' }) => {
  const [color, setColor] = useState(minColor);
  const [opacity, setOpacity] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
         
          const visibility = entry.intersectionRatio;
         
          setOpacity(visibility);
          setColor(interpolateColor(minColor, maxColor, visibility));
        } else {
          setOpacity(0);
          setColor(minColor); 
        }
      },
      {
        threshold: Array.from({ length: 101 }, (_, i) => i / 100), 
        rootMargin: '0px 0px -10% 0px', 
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [minColor, maxColor]);

  const interpolateColor = (color1, color2, ratio) => {
    const color1Rgb = hexToRgb(color1);
    const color2Rgb = hexToRgb(color2);
    const r = Math.round(color1Rgb.r + ratio * (color2Rgb.r - color1Rgb.r));
    const g = Math.round(color1Rgb.g + ratio * (color2Rgb.g - color1Rgb.g));
    const b = Math.round(color1Rgb.b + ratio * (color2Rgb.b - color1Rgb.b));
    return `rgb(${r}, ${g}, ${b})`;
  };

  const hexToRgb = (color) => {
    if (color.startsWith('rgba')) {
      const values = color.match(/rgba?\((\d+), (\d+), (\d+)(?:, (\d\.?\d*))?\)/);
      return {
        r: parseInt(values[1]),
        g: parseInt(values[2]),
        b: parseInt(values[3]),
      };
    } else if (color.startsWith('rgb')) {
      const values = color.match(/rgb\((\d+), (\d+), (\d+)\)/);
      return {
        r: parseInt(values[1]),
        g: parseInt(values[2]),
        b: parseInt(values[3]),
      };
    }
    return { r: 0, g: 0, b: 0 }; 
  };

  return (
    <div
      ref={elementRef}
      className={className}
      style={{
        color: color,
        opacity: opacity,
        transition: 'color 0.3s ease, opacity 0.3s ease', 
      }}
    >
      {children}
    </div>
  );
};

export default ScrollColorChange;
