import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(
      isExpanded ? [...expanded, panel] : expanded.filter((item) => item !== panel),
    );
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
        backgroundColor: '#000',  // Set the background to black
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'white',  // Make the title text white
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
          fontFamily: 'Roboto',
          fontWeight: '400',  // Removed bold
          fontSize: { xs: 32, md: 55 },  // Make text a bit bigger
        }}
      >
        Întrebări frecvente
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded.includes('panel1')}
          onChange={handleChange('panel1')}
          sx={{ backgroundColor: '#000', color: '#FFF' }} // Accordion background
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#FFFFFF' }} />}
            aria-controls="panel1d-content"
            id="panel1d-header"
            sx={{
              backgroundColor: '#000', 
              color: '#FFF', 
              borderBottom: '1px solid #444', // Added dark grey line
              padding: '16px',  // Increased padding for more spacing
            }} // AccordionSummary background
          >
            <Typography component="h3" variant="subtitle2" sx={{ color: '#FFFFFF', fontSize: '1.2rem', fontWeight: '400' }}>
              Există costuri pentru a mă alătura agenției?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#000', color: '#FFF', padding: '20px' }}>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' }, color: '#FFFFFF', fontSize: '1rem' }}
            >
              Nu, colaborarea cu Neb One Agency nu implică niciun cost. Ne concentrăm pe susținerea dezvoltării tale ca livestreamer și pe a-ți oferi toate resursele necesare pentru a crește, fără a impune taxe de înscriere sau alte comisioane.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded.includes('panel2')}
          onChange={handleChange('panel2')}
          sx={{ backgroundColor: '#000', color: '#FFF' }} // Accordion background
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#FFFFFF' }} />}
            aria-controls="panel2d-content"
            id="panel2d-header"
            sx={{
              backgroundColor: '#000', 
              color: '#FFF', 
               borderBottom: '1px solid #444',
              padding: '16px',  // Increased padding for more spacing
            }} // AccordionSummary background
          >
            <Typography component="h3" variant="subtitle2" sx={{ color: '#FFFFFF', fontSize: '1.2rem', fontWeight: '400' }}>
              Cum mă pot alătura agenției?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#000', color: '#FFF', padding: '20px' }}>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' }, color: '#FFFFFF', fontSize: '1rem' }}
            >
              Pentru a te alătura agenției, contactează-ne prin formularul de contact disponibil pe website-ul nostru și oferă-ne câteva detalii despre activitatea ta.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded.includes('panel3')}
          onChange={handleChange('panel3')}
          sx={{ backgroundColor: '#000', color: '#FFF' }} // Accordion background
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#FFFFFF' }} />}
            aria-controls="panel3d-content"
            id="panel3d-header"
            sx={{
              backgroundColor: '#000', 
              color: '#FFF', 
               borderBottom: '1px solid #444',
              padding: '16px',  // Increased padding for more spacing
            }} // AccordionSummary background
          >
            <Typography component="h3" variant="subtitle2" sx={{ color: '#FFFFFF', fontSize: '1.2rem', fontWeight: '400' }}>
              Cum pot începe să câștig bani prin Neb One Agency?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#000', color: '#FFF', padding: '20px' }}>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' }, color: '#FFFFFF', fontSize: '1rem' }}
            >
              Colaborând cu Neb One Agency, vei învăța principii și strategii care te vor ajuta să atragi un număr mai mare de spectatori activi în timpul livestream-urilor. Vom lucra împreună pentru a-ți crește vizibilitatea și engagement-ul, ceea ce va deschide oportunități de monetizare prin TikTok.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded.includes('panel4')}
          onChange={handleChange('panel4')}
          sx={{ backgroundColor: '#000', color: '#FFF' }} // Accordion background
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#FFFFFF' }} />}
            aria-controls="panel4d-content"
            id="panel4d-header"
            sx={{
              backgroundColor: '#000', 
              color: '#FFF', 
               borderBottom: '1px solid #444',
              padding: '16px',  // Increased padding for more spacing
            }} // AccordionSummary background
          >
            <Typography component="h3" variant="subtitle2" sx={{ color: '#FFFFFF', fontSize: '1.2rem', fontWeight: '400' }}>
              Cât timp durează până primesc un răspuns după înscriere?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#000', color: '#FFF', padding: '20px' }}>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' }, color: '#FFFFFF', fontSize: '1rem' }}
            >
              Încercăm să fim cât mai prompți în procesarea fiecărei cereri și să răspundem cât mai rapid. În majoritatea cazurilor, vei primi un răspuns din partea noastră în mai puțin de 24 de ore. Ne dorim să îți oferim o experiență rapidă și plăcută încă de la început.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded.includes('panel5')}
          onChange={handleChange('panel5')}
          sx={{ backgroundColor: '#000', color: '#FFF' }} // Accordion background
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#FFFFFF' }} />}
            aria-controls="panel5d-content"
            id="panel5d-header"
            sx={{
              backgroundColor: '#000', 
              color: '#FFF', 
               borderBottom: '1px solid #444',
              padding: '16px',  // Increased padding for more spacing
            }} // AccordionSummary background
          >
            <Typography component="h3" variant="subtitle2" sx={{ color: '#FFFFFF', fontSize: '1.2rem', fontWeight: '400' }}>
              Ce se întâmplă dacă nu mai doresc să colaborez cu agenția?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#000', color: '#FFF', padding: '20px' }}>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' }, color: '#FFFFFF', fontSize: '1rem' }}
            >
              Dacă decizi la un moment dat că nu mai dorești să colaborezi cu Neb One Agency, vom respecta decizia ta și vom încheia colaborarea într-un mod prietenos și fără complicații. Vrem ca fiecare parteneriat să fie bazat pe încredere și să rămână o experiență pozitivă pentru ambele părți.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}