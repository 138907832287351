import React, { useState, useEffect } from 'react';
import '../styles/SlidingCards.css'; 

const SlidingCards = () => {
  const [activeCard, setActiveCard] = useState(0);
  const [timerActive, setTimerActive] = useState(true); 

  const cards = [
    {
      icon: '/external/lifebuoy.svg',
      title: 'Support personalizat',
      description: 'Te ajutăm să îți crești audiența și să îți îmbunătățești performanțele pe TikTok, oferindu-ți ghidare și resurse pentru a te dezvolta ca livestreamer.'
    },
    {
      icon: '/external/briefcase.svg',
      title: 'Marketing și Branding',
      description: 'Îți oferim suportul necesar pentru a-ți dezvolta imaginea și a-ți consolida brandul personal, astfel încât să atragi mai mulți urmăritori și să devii un creator de conținut de succes.'
    },
    {
      icon: '/external/gear.svg',
      title: 'Asistență tehnică',
      description: 'Beneficiezi de suport tehnic disponibil 24/7 pentru a rezolva rapid orice problemă tehnică, astfel încât să îți menții audiența implicată și conectată.'
    },
    {
      icon: '/external/chart-line.svg',
      title: 'Analiză și optimizare',
      description: 'Analizăm performanțele tale și îți oferim sugestii pentru a-ți optimiza strategiile, astfel încât să maximizezi impactul fiecărui live și să atragi o audiență mai mare.'
    },
  ];

  const handleNext = () => {
    setActiveCard((prev) => (prev + 1) % cards.length);
    setTimerActive(false); 
  };

  const handlePrev = () => {
    setActiveCard((prev) => (prev - 1 + cards.length) % cards.length);
    setTimerActive(false);
  };

  const handleCardClick = (index) => {
    setActiveCard(index);
    setTimerActive(false); 
  };

  useEffect(() => {
    if (timerActive) {
      const interval = setInterval(() => {
        setActiveCard((prev) => (prev + 1) % cards.length);
      }, 5000); 

      return () => clearInterval(interval); 
    }
  }, [timerActive, cards.length]); 

  return (
    <div className="sliding-cards-container">
      <div className="cards-wrapper">
        {cards.map((card, index) => (
          <div
            key={index}
            className={`card ${index === activeCard ? 'active' : ''} ${index > activeCard ? 'next' : ''} ${index < activeCard ? 'prev' : ''}`}
            onClick={() => handleCardClick(index)}
          >
            <img src={card.icon} alt={card.title} className="card-icon" />
            <h3 className="card-title">{card.title}</h3>
            <p className="card-description">{card.description}</p>
          </div>
        ))}
      </div>

      <div className="navigation">
        <button onClick={handlePrev} className="arrow prev">&#8249;</button>
        <button onClick={handleNext} className="arrow next">&#8250;</button>
      </div>
    </div>
  );
};

export default SlidingCards;
