import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/HeaderPhone.css';

const PhoneHeader = () => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const location = useLocation(); 

    const toggleDropdown = () => {
        if (isDropdownOpen) {
            const menu = document.querySelector('.dropdown-menu');
            menu.classList.remove('open'); 
            setTimeout(() => {
                setDropdownOpen(false); 
            }, 300); 
        } else {
            setDropdownOpen(true); 
        }
    };

    return (
        <header className="site-phone">
            <div className="phone-logo">
                <Link to="/" className="logo-link">
                    <img src="/external/headerlogo.png" alt="Logo" />
                </Link>
            </div>
            <button
                className={`dropdown-toggle ${isDropdownOpen ? 'open' : ''}`}
                onClick={toggleDropdown}
                aria-label="Toggle menu"
            >
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </button>
            {isDropdownOpen && (
                <div className="dropdown-menu open">
                    <Link
                        to="/"
                        className={`dropdown-link ${location.pathname === '/' ? 'active' : ''}`}
                        onClick={() => setDropdownOpen(false)}
                    >
                        HOME
                    </Link>
                    <Link
                        to="/echipa"
                        className={`dropdown-link ${location.pathname === '/echipa' ? 'active' : ''}`}
                        onClick={() => setDropdownOpen(false)}
                    >
                        ECHIPA
                    </Link>
                    <Link
                        to="/contact"
                        className={`dropdown-link ${location.pathname === '/contact' ? 'active' : ''}`}
                        onClick={() => setDropdownOpen(false)}
                    >
                        CONTACT
                    </Link>
                </div>
            )}
        </header>
    );
};

export default PhoneHeader;