import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/HeaderEchipa.css';

const EchipaHeader = () => { 
  const [activeLink, setActiveLink] = useState('/echipa');

  return (
    <header className="echipa-site-header">
      <div className="echipa-header-content">
        <div className="echipa-header-links-left">
          <div className="echipa-header-logo">
            <Link to="/" className="logo-link">
              <img src="/external/headerlogo.png" alt="Logo" />
            </Link>
          </div>
          <Link
            to="/"
            className="echipa-header-item"
            onClick={() => setActiveLink('/')}
          >
            HOME
          </Link>
          <Link 
          to='/contact'
          className='echipa-header-item'
          onClick={() => setActiveLink('/contact')}
          >
            CONTACT
          </Link>
        </div>
        <div className="echipa-header-links-right">
          <span className="echipa-header-item active">ECHIPA</span>
        </div>
      </div>
    </header>
  );
};

export default EchipaHeader;