import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/header.css';

const Header = () => {
  const [activeLink, setActiveLink] = useState('/');

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  return (
    <header className="site-header">
      <div className="header-content">
        <div className="header-links-left">
          <div className="header-logo">
            <Link to="/" className="logo-link">
              <img src="/external/headerlogo.png" alt="Logo" />
            </Link>
          </div>
          <span
            className={`header-item ${activeLink === '/' ? 'active' : ''}`}
            onClick={() => handleLinkClick('/')}
          >
            HOME
          </span>
          <Link
            to="/echipa"
            className={`header-item ${activeLink === '/echipa' ? 'active' : ''}`}
            onClick={() => handleLinkClick('/echipa')}
          >
            ECHIPA
          </Link>
        </div>

        <div className="header-links-right">
          <Link
            to="/contact"
            className={`header-item ${activeLink === '/contact' ? 'active' : ''}`}
            onClick={() => handleLinkClick('/contact')}
          >
            CONTACT
          </Link>

          <Link
            to='/contact'
            className='buton-header-contact'
            onClick={() => handleLinkClick('/contact')}
          >
            <button className="header-button">INSCRIE-TE</button>
          </Link>

        </div>
      </div>
    </header>
  );
};

export default Header;
