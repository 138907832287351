import { React, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/homepage.css';
import Header from '../components/Header.js';
import FAQList from '../components/FAQList';
import ScrollColorChange from '../components/ScrollColorChange';
import SlidingCards from '../components/SlidingCards';
import BeforeAndAfter from '../components/BeforeAndAfter';
import Footer from '../components/Footer';
import PhoneHeader from '../components/HeaderPhone.js';

const Homepage = (props) => {

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/contact');
  };

  return (
    <div className="homepage-container">

      <Helmet>
        <title>NEB ONE AGENCY</title>
      </Helmet>

      <div className='desktop-header'><Header /></div>
      <div className='phone-header'><PhoneHeader /></div>

      <div className="homepage-homepage">

        <div className="homepage-headertext">
          <div className="background-video-container">
            <video autoPlay loop muted playsInline className="background-video">
              <source src="/external/background.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <span className="homepage-text10">
            <span>
              Mai mult decât
              <span dangerouslySetInnerHTML={{ __html: ' ' }} />
            </span>
            <br />
            <span>o simplă agenție</span>
          </span>

          <span className="homepage-text14">
            Neb One Agency este partenerul tău de încredere în lumea livestreaming-ului pe TikTok.
            Ne dedicăm să sprijinim livestreamerii să își dezvolte brandul, să atragă o comunitate
            dedicată și să maximizeze impactul fiecărui live.
          </span>

          <button className="homepage-buton" onClick={handleButtonClick}>
            INSCRIE-TE
          </button>
        </div>

        <div className="homepage-before-after">
          <BeforeAndAfter />
        </div>

        <div className="homepage-beneficii">
          <div className="homepage-header">
            <span className="homepage-text24">Beneficiile de care o să te bucuri</span>
            <span className="homepage-text25">
              Acestea sunt câteva beneficii pe care le vei avea din moment ce ni te alături
            </span>
          </div>
          <SlidingCards />
        </div>
        
        <div className="homepage-reviews">
          <div className='homepage-ghilimele'>
            "
          </div>
          <ScrollColorChange className="homepage-text31" colorOnScroll="white" scrollThreshold={50}>
            Colaborarea cu Neb One Agency mi-a schimbat complet perspectiva
            asupra livestreaming-ului. Echipa m-a ajutat să îmi dezvolt stilul
            și să atrag o comunitate dedicată. Datorită sfaturilor lor, am
            reușit să îmi cresc audiența cu peste 300% în doar două luni ! Nu aș
            fi ajuns aici fără sprijinul lor constant.
          </ScrollColorChange>
          <div className='homepage-numer'>
            <div className="homepage-text32">@andreea_dh</div>
            <div className='homepage-sep'>
              <img
                src="/external/rectangle25744-vtk-200w.png"
                alt="Rectangle25744"
                className="homepage-rectangle25"
              />
              <span className="homepage-text33">
                Livestreamer, Echipa Neb One Agency
              </span>
            </div>
          </div>
        </div>
      </div>
      <FAQList />
      <div className='homepage-footer'><Footer /></div>
    </div>
  )
}

export default Homepage
