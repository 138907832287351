import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/HeaderContact.css';

const ContactHeader = () => {
  const [activeLink, setActiveLink] = useState('/contact');

  return (
    <header className="contact-site-header">
      <div className="contact-header-content">
        <div className="contact-header-links-left">
          <div className="contact-header-logo">
            <Link to="/" className="logo-link">
              <img src="/external/headerlogo.png" alt="Logo" />
            </Link>
          </div>
          <Link
            to="/"
            className="contact-header-item"
            onClick={() => setActiveLink('/')}
          >
            HOME
          </Link>
          <Link
            to="/echipa"
            className="contact-header-item"
            onClick={() => setActiveLink('/echipa')}
          >
            ECHIPA
          </Link>
        </div>

        <div className="contact-header-links-right">
          <span className="contact-header-item active">CONTACT</span>
        </div>
      </div>
    </header>
  );
};

export default ContactHeader;
