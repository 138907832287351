import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-line"></div>
      <p className="footer-text">Copyright &copy; NEB ONE AGENCY 2024</p>
    </footer>
  );
};

export default Footer;
