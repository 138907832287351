import React from 'react'
import '../styles/Echipa.css'
import EchipaHeader from '../components/HeaderEchipa'
import PhoneHeader from '../components/HeaderPhone'
import Footer from '../components/Footer'

const Echipa = () => {
    return (
        <div className='echipa-page'>
            <div className='echipa-phone-header'><PhoneHeader /></div>
            <div className='echipa-header'><EchipaHeader /></div>
            <div className='echipa-container'>
                <div className='echipa-headertext'>
                    <h2 className='echipa-headertitle'>Echipa<br />NEB ONE AGENCY</h2>
                    <div className='echipa-headerp'>Aceasta este echipa NEB ONE AGENCY care te va ajuta pe parcursul drumului tău ca streamer.</div>
                </div>
                <div className='echipa-team'>

                    <div className='echipa-team-neb'>
                        <div className='echipa-team-neb-titlu'>
                            <div className='echipa-team-neb-nume'>NEB1</div>
                            <div className='echipa-team-neb-linie'></div>
                        </div>
                        <div className='echipa-team-neb-info'>
                            <div className='echipa-team-neb-mail'>Mail: neb1@nebone.ro</div>
                            <div className='echipa-team-neb-tt'>TikTok: @e.neb1</div>
                        </div>
                        <div className='echipa-team-neb-butoane'>
                            <a href="mailto:neb1@nebone.ro" class="btn n-m">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon">
                                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" fill="#ffffff" />
                                </svg>
                            </a>
                            <a href="https://www.tiktok.com/@e.neb1" class="btn n-t">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="icon">
                                    <path fill="#ffffff" d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                                </svg>
                            </a>
                        </div>
                    </div>

                    <div className='echipa-team-andreea'>
                        <div className='echipa-team-andreea-titlu'>
                            <div className='echipa-team-andreea-nume'>Andreea</div>
                            <div className='echipa-team-andreea-linie'></div>
                        </div>
                        <div className='echipa-team-andreea-info'>
                            <div className='echipa-team-andreea-mail'>Mail: andreea@nebone.ro</div>
                            <div className='echipa-team-andreea-tt'>TikTok: @andreea_dh</div>
                        </div>
                        <div className='echipa-team-neb-butoane'>
                            <a href="mailto:andreea@nebone.ro" class="btn a-m">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon">
                                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" fill="#ffffff" />
                                </svg>
                            </a>
                            <a href="https://www.tiktok.com/@andreea_dh" class="btn a-t">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="icon">
                                    <path fill="#ffffff" d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                                </svg>
                            </a>
                        </div>
                    </div>

                    <div className='echipa-team-cos'>
                        <div className='echipa-team-cos-titlu'>
                            <div className='echipa-team-cos-nume'>Cos</div>
                            <div className='echipa-team-cos-linie'></div>
                        </div>
                        <div className='echipa-team-cos-info'>
                            <div className='echipa-team-cos-mail'>Mail: cos@nebone.ro</div>
                            <div className='echipa-team-cos-tt'>TikTok: @cos_fx</div>
                        </div>
                        <div className='echipa-team-neb-butoane'>
                            <a href="mailto:cos@nebone.ro" class="btn c-m">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon">
                                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" fill="#ffffff" />
                                </svg>
                            </a>
                            <a href="https://www.tiktok.com/@cos_fx" class="btn c-t">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="icon">
                                    <path fill="#ffffff" d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Echipa;