import React, { useState } from 'react';
import handleViewport from 'react-in-viewport';
import '../styles/BeforeAndAfter.css';

// Block Component
const Block = ({ inViewport, forwardedRef }) => {
  const [hasBeenInViewport, setHasBeenInViewport] = useState(false);

  if (inViewport && !hasBeenInViewport) {
    setHasBeenInViewport(true);
  }

  const visibility = hasBeenInViewport ? 'visible' : '';

  return (
    <div ref={forwardedRef} className="homepage-before-after1">
      <div className="homepage-before-after2">
        <div className={`homepage-viewers1 ${visibility}`}>
          <span className="homepage-text16">10</span>
          <span className="homepage-text17">Viewers</span>
        </div>
        <div className={`homepage-coins1 ${visibility}`}>
          <span className="homepage-text18">100</span>
          <span className="homepage-text19">Coins</span>
        </div>
        <div className={`homepage-grey-circles ${visibility}`}>
          <img src="/external/grey-circles.svg" alt="Grey Circles" />
        </div>
        <div className={`homepage-grey-circle ${visibility}`}>
          <img src="/external/grey-circle.svg" alt="Grey Circle" />
        </div>
        <div className={`homepage-neboneemblem1 ${visibility}`}>
          <img 
            src="/external/neboneemblem11226-8p6f-200h.png" 
            alt="Logo" 
            style={{
              width: '221px',
              height: '125px',
              objectFit: 'contain'
            }}
          />
        </div>
        <div className={`homepage-yellowcircles ${visibility}`}>
          <img src="/external/yellowcircles1242-kudo.svg" alt="Yellow Circles" />
        </div>
        <div className={`homepage-yellowarrow ${visibility}`}>
          <img src='external/yellowarrow.svg' alt='Yellow Arrow' />
        </div>
        <div className={`homepage-viewers2 ${visibility}`}>
          <span className="homepage-text20">100+</span>
          <span className="homepage-text21">Viewers</span>
        </div>
        <div className={`homepage-coins2 ${visibility}`}>
          <span className="homepage-text22">5000+</span>
          <span className="homepage-text23">Coins</span>
        </div>
      </div>
    </div>
  );
};

// Wrap Block with handleViewport for visibility detection
const ViewportBlock = handleViewport(Block, { rootMargin: '-1.0px' });

// Main Component
const BeforeAndAfter = () => {
  return (
    <div>
      <ViewportBlock />
    </div>
  );
};

export default BeforeAndAfter;