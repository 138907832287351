import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'

import './style.css'
import Homepage from './views/homepage'
import NotFound from './views/not-found'
import ContactPage from './pages/ContactPage.js'
import Echipa from './pages/Echipa.js'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="*" element={<NotFound />} />
        <Route path="*" element={<Navigate to="*" />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path='/echipa' element={<Echipa />} />
      </Routes>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
