import React, { useState } from 'react';
import '../styles/ContactPage.css';
import Footer from '../components/Footer';
import HeaderContact from '../components/HeaderContact';
import PhoneHeader from '../components/HeaderPhone';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    tiktokUsername: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:5000/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Mail-ul dumneavoastră a fost trimis! Echipa noastră vă va contacta în cel mai scurt timp.');
      } else {
        alert('Ceva a mers prost, vă rugăm să încercați din nou.');
      }      
    } catch (error) {
      console.error('Error:', error);
      alert('Ceva a mers prost, vă rugăm să încercați din nou.');
    }
  };

  return (
    <div className="contact-container">
      <div className='contact-header'><HeaderContact /></div>
      <div className='contact-phone-header'><PhoneHeader /></div>
      <h2 className="contact-headertext">Înscrie-te</h2>
      <div className='contact-headertextline'></div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nume</label>
          <input 
            type="text" 
            id="name" 
            name="name" 
            value={formData.name} 
            onChange={handleChange} 
            placeholder="Introduceți numele complet" 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            placeholder="Introduceți adresa de email" 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="tiktokUsername">TikTok Username</label>
          <input 
            type="text" 
            id="tiktokUsername" 
            name="tiktokUsername" 
            value={formData.tiktokUsername} 
            onChange={handleChange} 
            placeholder="Introduceți numele de TikTok cu @"
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Descrie-te pe tine și conținutul tău</label>
          <textarea 
            id="message" 
            name="message" 
            rows="5" 
            value={formData.message} 
            onChange={handleChange} 
            placeholder="Scrieți mesajul dumneavoastră aici..." 
            required 
          />
        </div>
        <button type="submit" className="contact-submit">Trimite</button>
      </form>
      <Footer />
    </div>
  );
};

export default ContactPage;
